import React from 'react';
import Carousel from 'react-bootstrap/Carousel';

import Container from 'react-bootstrap/Container';

function CarouselSection() {
  return (
    <div className="site-section" id="remove-padding2">
      <Container className="carousel">
        <Carousel>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="/images/ab_1.jpg"
                    // src= "/images/teamphotos/IMG_4463.PNG"
              alt="Group of friends smiling at a device."
            />
            {/* <Carousel.Caption>
                    <h3>First slide label</h3>
                    <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                </Carousel.Caption> */}
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="/images/ab_1.png"
                    // src= "/images/teamphotos/Copy of profile-pic.jpeg"

              alt="Third slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="/images/ab_5.jpg"
                    // src = "/images/teamphotos/havakanebio.jpg"
              alt="Third slide"
            />
          </Carousel.Item>
        </Carousel>
      </Container>
    </div>
  );
}

export default CarouselSection;
